import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import config from '../utils/siteConfig';
import styled from 'styled-components';

const Home = styled.div`
  padding-top: 0;
  padding-bottom: 6rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  li {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -.25px;
  }
`;

const Summary = styled.div`
  padding-bottom: 4rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-bottom: 0;
  }
`;

const Headline = styled.h1`
  margin-bottom: 2rem;
  font-size: 3rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  .ampersand {
    font-family: PostGrotesk, Georgia, sans-serif;
    font-weight: 400;
    opacity: .8;
    display: inline;
    line-height: 0.85;
  }
`;

const Subheadline = styled.div`
  h2 {
    font-weight: 400;
    opacity: .9;
    font-size: 1rem;
    margin-bottom: 0;

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      margin-bottom: 0;
    }
  }
`;

const Index = ({ data, pageContext }) => {
  const { title, headline, lead } = data.contentfulLayout;

  return (
    <Layout>
      <Helmet>
        <title>{`${config.siteTitle} | ${title}`}</title>
      </Helmet>
      <SEO />
      <section className="section reveal">
        <Home className="container">
          <Summary className="columns is-hidden-mobile">
            <div className="column is-one-fifth"></div>
            <div className="column is-three-fifths is-full-mobile">
              <Subheadline
                dangerouslySetInnerHTML={{
                  __html: lead.childMarkdownRemark.html,
                }}
              />
            </div>
          </Summary>
          <div className="columns is-multiline-mobile">
            <div className="column is-one-fifth is-hidden-mobile"></div>
            <div className="column is-four-fifths is-full-mobile">
              <Headline
                dangerouslySetInnerHTML={{ __html: headline.internal.content }}
              />
            </div>
          </div>
          <Summary className="columns is-hidden-tablet">
            <div className="column is-three-fifths is-full-mobile">
              <Subheadline
                dangerouslySetInnerHTML={{
                  __html: lead.childMarkdownRemark.html,
                }}
              />
            </div>
          </Summary>
          <div className="columns">
            <div className="column is-one-fifth is-hidden-mobile"></div>
            <div className="column is-one-fifths">
              <h3>What we do</h3>
              <ul>
                <li>Product strategy</li>
                <li>Service design</li>
                <li>Interface design</li>
                <li>User research</li>
                <li>Usability testing</li>
                <li>Workshops</li>
                <li>Education</li>
              </ul>
            </div>
            <div className="column is-three-fifths">
              <h3>Some of our clients</h3>
              <ul>
                <li>24/7 InTouch</li>
                <li>Ducks Unlimited Canada</li>
                <li>Emerge Knowledge</li>
                <li>IBEX Payroll</li>
                <li>Johnston Group</li>
                <li>Neovation</li>
                <li>Ultimate Canada</li>
                <li>UnionWare</li>
              </ul>
            </div>
          </div>
        </Home>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    contentfulLayout(title: { eq: "Home" }) {
      title
      id
      slug
      metaDescription {
        internal {
          content
        }
      }
      headline {
        internal {
          content
        }
      }
      lead {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default Index;
